import { format } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { th } from 'date-fns/locale'
import { isInteger, floor } from 'mathjs'

export const dateFormat = (date: string | Date, template: string): string => {
  if (date) {
    const christianYear = format(new Date(date), 'yyyy')
    const buddhishYear = (parseInt(christianYear) + 543).toString()

    return format(toZonedTime(date, 'Asia/Bangkok'), template, {
      locale: th
    }).replace(christianYear, buddhishYear)
  } else {
    return '-'
  }
}

export const calculateExpireDateRange = (expireDate: number) => {
  const dayPerMonth = 30
  if (expireDate < dayPerMonth) {
    return `${expireDate} วัน`
  } else {
    const findMonth = expireDate / dayPerMonth // หาเดือน
    const checkInteger = isInteger(findMonth) // เช็คว่าเป็นจํำนวนเต็มไหม
    if (checkInteger) {
      return `${findMonth} เดือน`
    } else {
      const months = floor(findMonth) // ตัดทศนิยม
      const days = expireDate % dayPerMonth
      return `${months} เดือน ${days} วัน`
    }
  }
}
