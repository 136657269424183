'use client'

import classNames from 'classnames'
import Image from 'next/image'
import chatContact from '@/app/icons/priceIcon/chat-contact.svg'
import headCard from '@/app/icons/priceIcon/headCard.svg'
import cardCenter from '@/app/icons/priceIcon/card-center.svg'
import Link from 'next/link'

export default function PricingContact({ promotionShow }: { promotionShow: boolean }) {
  return (
    <div className="max-w-[222.4px] w-full h-full">
      <div className={classNames('w-[222.4px]', promotionShow ? 'h-[361px]' : 'h-[323px]')}>
        <div className="h-full p-4 bg-gradient-to-b from-primary-ocean-blue-base to-primary-ocean-blue-fate rounded-wrapAround-3">
          <div className="h-[44px] pb-2 w-full flex items-center justify-center">
            <Image width={10} height={10} src={headCard} alt="headCard" />
            <h3 className="pl-1 text-white">พิเศษสุดสำหรับคุณ</h3>
          </div>
          <div
            className={classNames(
              'w-fll flex items-center flex-row',
              !promotionShow ? 'h-[213px] ' : 'h-[248px]'
            )}
          >
            <div className="w-[119px] mx-auto text-center flex justify-center items-center">
              <div className="w-full flex-row justify-center items-center">
                <div className="w-full justify-center flex">
                  <Image src={cardCenter} alt="cardCenter" />
                </div>
                <div className="pt-4">
                  <p className="text-white-true text-MediumSemiBold">ยังไม่ตรงใจใช่มั้ย?</p>
                  <p className="text-white-true text-MediumSemiBold">
                    ให้เราช่วยจัด แพ็กเกจให้ตรงใจ ตัวคุณมากขึ้น
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Link
            target="_blank"
            href={'https://line.me/R/ti/p/@199cqywi?from=page&searchId=199cqywi'}
          >
            <button
              type="button"
              className="w-full flex items-center justify-between py-2 bg-white rounded-friendly"
            >
              <div className="flex hover:opacity-70 items-center justify-center w-full text-primary-ocean-blue-600 text-MediumSemiBold">
                <Image width={17} height={16} src={chatContact} alt="chatContact" />
                <span className="pl-1">ติดต่อเรา</span>
              </div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
