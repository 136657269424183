'use client'

import Image from 'next/image'
import ReactGA from 'react-ga4'
import checkCircle from '@/app/icons/priceIcon/check-circle.svg'
import classNames from 'classnames'
import IPackageModule from '../models/packageModule'
import Link from 'next/link'
// import { useTranslations } from 'next-intl'

export default function PricingBoxPage({
  item,
  promotionShow
}: {
  item: IPackageModule
  promotionShow: boolean
}): React.ReactElement {
  return (
    <div className={classNames('min-h-[332px] max-h-[376px] !w-[222.4px]')}>
      <div className="border bg-white border-primary-ocean-blue-base w-full shadow-pricing rounded-wrapAround-3 backdrop-blur-[16px]">
        <div className="py-4 pt-4">
          <h3 className="w-full pb-3 px-4 h-[32px] !max-w-h-[32px] flex items-center justify-between">
            <p className="text-neutral-gray-700 font-sukhumvit font-sukhumvitw700 text-sukhumvit20">
              {item.name}
            </p>
            <div
              className={classNames(
                'w-[53px] h-[28px] flex items-center px-1 justify-center bg-gradient-to-b from-[#5CCBC4] to-[#5CAAC7] rounded-full',
                item.isPopular ? 'block' : 'hidden'
              )}
            >
              <p className="text-white-true text-chatthai12 font-chatthai font-chatthaiw400 pl-0.5">
                ยอดนิยม
              </p>
            </div>
          </h3>
          {item.packageGroupId !== 1 ? (
            <div
              className={classNames(
                'w-full flex flex-col p-4 justify-start bg-gradient-to-t to-primary-ocean-blue-base from-primary-ocean-blue-fate',
                promotionShow ? 'h-[148px] max-h-[148px]' : 'h-[110px] min-h-[110px]'
              )}
            >
              <div>
                <div className="text-white-true flex items-baseline">
                  <span
                    className={classNames(
                      promotionShow
                        ? 'block text-sukhumvit40 font-sukhumvit font-sukhumvitw700'
                        : 'hidden'
                    )}
                  >
                    {Number(item.price.toFixed()).toLocaleString()}
                  </span>
                  <span
                    className={classNames(
                      !promotionShow
                        ? 'block text-sukhumvit40 font-sukhumvit font-sukhumvitw700'
                        : 'hidden'
                    )}
                  >
                    {Number(item.rawPrice.toFixed()).toLocaleString()}
                  </span>
                  <span className="font-sukhumvit font-sukhumvitw700 text-sukhumvit20 pl-2">
                    บาท
                  </span>
                </div>
                <div
                  className={classNames(
                    'text-sukhumvit16 font-sukhumvit font-sukhumvitw700',
                    promotionShow && item.discount > 0 ? 'block' : 'hidden'
                  )}
                >
                  <span className="text-danger-orange-red-600 line-through">
                    {item.rawPrice.toLocaleString()}
                  </span>
                  <span className="pl-1 text-white-true">-{item.discount}%</span>
                </div>
              </div>
              <div>
                <div className="text-white-true pt-2">
                  <span className="text-sukhumvit28 font-sukhumvit font-sukhumvitw700">
                    {item.pricePerSlip}
                  </span>
                  <span className="text-sukhumvit20 font-sukhumvit font-sukhumvitw700 pl-2">
                    บาท/สลิป
                  </span>
                </div>
                <div
                  className={classNames(promotionShow && item.discount > 0 ? 'block' : 'hidden')}
                >
                  <span className="text-danger-orange-red-600 line-through text-sukhumvit20 font-sukhumvit font-sukhumvitw400">
                    {item.rawPricePerSlip}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                'w-full px-4 flex items-center text-sukhumvit60 font-sukhumvit font-sukhumvitw700 text-info-light-blue-base',
                promotionShow
                  ? 'h-[148px] max-h-[148px] min-h-max'
                  : 'h-[110px] min-h-[110px] max-h-max'
              )}
            >
              ฟรี
            </div>
          )}
          <div className="py-6 mx-4 border-b border-neutral-300">
            <div className="max-h-[128px] h-full">
              <div className="flex items-center h-full">
                <div>
                  <Image src={checkCircle} alt="checkCircle" />
                </div>
                <div className="font-chatthai font-chatthaiw400 text-chatthai16 pl-1 text-neutral-gray-700">
                  จำนวน{' '}
                  <span className="text-primary-ocean-blue-600">
                    {item.slipQuota.toLocaleString()}
                  </span>{' '}
                  สลิป
                </div>
              </div>
              <div className="flex items-center">
                <div>
                  <Image src={checkCircle} alt="checkCircle" />
                </div>
                <div className="font-chatthai font-chatthaiw400 text-chatthai16 pl-1 text-neutral-gray-700">
                  ระยะเวลาใช้งาน{' '}
                  <span className="text-primary-ocean-blue-600">{item.expireDate}</span> วัน
                </div>
              </div>
              <div className="flex">
                <div className="flex-none pt-1.5">
                  <Image src={checkCircle} alt="checkCircle" />
                </div>
                <div className="font-chatthai font-chatthaiw400 text-chatthai16 pl-1 text-neutral-gray-700">
                  สลิปเกิน <span className="text-primary-ocean-blue-600">{item.pricePerSlip}</span>{' '}
                  บาท/สลิป
                </div>
              </div>
            </div>
          </div>
          <Link
            href={process.env.NEXT_PUBLIC_LOGIN || ''}
            onClick={(): void => {
              ReactGA.event({
                category: 'package_price',
                action: 'Click',
                label: 'select_pack_home'
              })
            }}
          >
            <button type="button" className="w-full flex items-center justify-between pt-2">
              <div className="flex hover:opacity-70 items-center justify-center w-full text-info-light-blue-base text-sukhumvit16 font-sukhumvit font-sukhumvitw700">
                เลือกแพ็กเกจนี้
              </div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
